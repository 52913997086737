<template>
  <div>
    <swag-page />
  </div>
</template>

<script>
  export default {
    name: 'ClubSwag',

    components: {
      SwagPage: () => import('@/components/swag/ClubSwagMain'),

    },
  }
</script>
