<template>
  <div>
    <contact-cards />
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    components: {
      ContactCards: () => import('@/components/base/ContactCards'),

    },
  }
</script>
