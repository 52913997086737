<template>
  <v-container>
    <section-title
      id="theme-features"
      class="spacing-playground pa-10"
    />
    <section-heading
      title="Ranges"
    />

    <v-row
      no-gutters
    >
      <v-col
        id="leftCol"
        class="pa-1"
        cols="12"
        md="6"
      >
<!-- eslint-disable -->
  <section>

    <div class=" pb-4 text-center text-h5 text--primary ">Indoor</div>
 

        <v-carousel
          height="auto"
        >
          <v-carousel-item
            class="carousel"
            v-for="(item,i) in Indoor"
            :key="i"
            :src="item.pathLong"
            transition="fade"
            contain
          ></v-carousel-item>
        </v-carousel>

  </section>

</v-col>
    <v-col
      id="leftCol"
      class="pa-1"
      cols="12"
      md="6"
      
      
      >
<!-- eslint-disable -->
  <section>

    <div class=" pb-4 text-center text-h5 text--primary ">Outdoor</div>
 

        <v-carousel
          height="auto"
        >
          <v-carousel-item
            v-for="(item,i) in Rosalie"
            :key="i"
            :src="item.pathLong"
            reverse-transition="fade"
            transition="fade"
            contain
          ></v-carousel-item>
        </v-carousel>
 
  </section>
</v-col>
</v-row>
    </v-container>
</template>

<script>
  export default {
    components: {
      SectionTitle: () => import('@/components/base/SectionTitle'),
      SectionHeading: () => import('@/components/base/SectionHeading'),

    },
    data () {
      return {
        Rosalie: [],
        Indoor: [],
      }
    },
    mounted () {
      this.importAll(require.context('@/assets/ranges/', true, /\.jpg$/))
      this.importIndoor(require.context('@/assets/indoorrange/', true, /\.jpg$/))
    },
    methods: {
      importAll (r) {
        r.keys().forEach(key => (this.Rosalie.push({ pathLong: r(key), pathShort: key })))
      },
      importIndoor (r) {
        r.keys().forEach(key => (this.Indoor.push({ pathLong: r(key), pathShort: key })))
      },
    },
  }
</script>

<style>
.v-carousel__item {
  background-size: 100% auto!Important;

}
</style>
