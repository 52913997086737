<template>
  <div>
    <history />
  </div>
</template>

<script>
  export default {
    name: 'Forms',
    components: {
      History: () => import('@/components/History/HistoryMain'),

    },
  }
</script>
