<template>
  <v-container class="pt-8">
    <section-title
      id="theme-features"
      class="spacing-playground pa-8"
    />
    <section-heading
      title="Sponsorship"
    />
    <p class=" text-h5">
      We are consistently working on improving our indoor and outdoor ranges at Blackhawk Archers.  To help us reach our goals, we are asking for sponsors to assist us.  In return for your cash donation, each outdoor range bunker and practice bunkers will display a sponsor name and also be included on our sponsor signs inside and outside the club.  We value your donations and encourage our members & visitors to patronize your businesses in return.
    </p>
    <p class="text-h5">Range sponsorships can also be purchased in memoriam of deceased family and friends who shared a passion for archery shooting.  Included below are samples of how others have shared their loved ones memories. </p>

    <p class="text-h5">If you would like to become a sponsor, please complete
      <a
        class="font-weight-bold"
        href="https://drive.google.com/file/d/1HSq2IgRK-nqSASP-5pdGVmbYJO-fhoCY/view?usp=sharing"
        target="_blank"
      >
        THIS FORM
      </a>
      .
    </p>
    <p class="text-h5">
      Send To:
      <br> Blackhawk Archers Inc
      <br> PO Box 5
      <br> Custer, WI  54423
    </p>
    <v-img
      src="https://drive.google.com/uc?id=1vyFnGcm0SQG6Xj3FPX3Ok_8VcsYTZtoJ"
      class=" mx-auto"
      contain
      align-center
      @click="$vuetify.goTo(0)"
    />
  </v-container>
</template>

<script>
  export default {
    name: 'FormsMain',
    components: {
      SectionTitle: () => import('@/components/base/SectionTitle'),
      SectionHeading: () => import('@/components/base/SectionHeading'),
    },
  }
</script>
