import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    articles: require('@/data/articles.json'),
    drawer: false,
    items: [
      {
        text: 'Home',
        href: '/',
      },
    ],
  },
  getters: {
    categories: state => {
      const categories = []

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find(category => category.text === article.category)
        ) continue

        const text = article.category
        const links = article.links
        categories.push({
          text,
          href: links,
        })
      }
      return categories.sort().slice(0, 6)
    },
    links: (state, getters) => {
      return state.items.concat(getters.categories)
    },
    generateLinks (state) {
    const linkList = [
      {
        text: 'Home',
        href: '/',
      },
      {
        text: 'Calendar of Events',
        href: '/CalendarofEvents',
      },
      {
        text: 'Membership',
        href: '/Forms',
      },
      {
        text: 'Event Details / Results',
        href: '/Events',
      },
      {
        text: 'League Info',
        href: '/LeagueInfo',
      },
      {
        text: 'Sponsorship',
        href: '/Sponsorship',
      },
      {
        text: 'Youth Archery Team',
        href: '/YouthArcheryTeam',
      },
      {
        text: 'History',
        href: '/History',
      },
      {
        text: 'Club Swag',
        href: '/ClubSwag',
      },
      {
        text: 'Photo Gallery',
        href: '/PhotoGallery',
      },
      {
        text: 'Contact',
        href: '/Contact',
      },

    ]
    return linkList
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {

  },
})
