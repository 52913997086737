<template>
  <v-container>
    <section-title
      id="theme-features"
      class="spacing-playground pa-10"
    />
    <section-heading
      title="HHA 2021"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="(Item, i) in HHAPhotos"
          :key="i"
          cols="12"
          md="6"
        >
          <h-h-a-card
            v-bind="Item"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  export default {
    name: 'PhotoGallary',
    components: {
      SectionTitle: () => import('@/components/base/SectionTitle'),
      SectionHeading: () => import('@/components/base/SectionHeading'),
      HHACard: () => import('@/components/HHA/HHACard'),
    },
    data: () => ({
      HHAPhotos: [],
    }),
    mounted () {
      this.importHHA(require.context('@/assets/HHA/', true, /\.jpg$/))
    },
    methods: {
      importHHA (r) {
        r.keys().forEach(key => (this.HHAPhotos.push({ pathLong: r(key), pathShort: key, link: r(key) })))
      },
    },
  }
</script>
