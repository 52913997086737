import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Contact from '@/views/Contact.vue'
import ClubSwag from '@/views/ClubSwag.vue'
import Ranges from '@/views/Ranges.vue'
import SpotLeagueShoots from '@/views/SpotLeagueShoots.vue'
import HHA from '@/views/HHA.vue'
import Forms from '@/views/Forms.vue'
import Events from '@/views/Events.vue'
import History from '@/views/History.vue'
import Calendar from '@/views/Calendar.vue'
import Youth from '@/views/YouthArcheryTeam.vue'
import HHA2021 from '@/components/HHA/HHAPictures.vue'
import RangePictures from '@/components/HHA/RangeMainPage.vue'
import Sponsorship from '@/components/sponsorships/SponsorshipMain.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/Contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/ClubSwag',
      name: 'clubswag',
      component: ClubSwag,
    },
    {
      path: '/Ranges',
      name: 'ranges',
      component: Ranges,
    },
    {
      path: '/LeagueInfo',
      name: 'League Info',
      component: SpotLeagueShoots,
    },
    {
      path: '/PhotoGallery',
      name: 'Photo Gallery',
      component: HHA,
    },
    {
      path: '/Forms',
      name: 'forms',
      component: Forms,
    },
    {
      path: '/Events',
      name: 'Events',
      component: Events,
    },
    {
      path: '/History',
      name: 'history',
      component: History,
    },
    {
      path: '/CalendarofEvents',
      name: 'Calendar of Events',
      component: Calendar,
    },
    {
      path: '/YouthArcheryTeam',
      name: 'Youth Archery Team',
      component: Youth,
    },
    {
      path: '/HHA2021',
      name: 'HHA2021',
      component: HHA2021,
    },
    {
      path: '/RangePictures',
      name: 'Range Pictures',
      component: RangePictures,
    },
    {
      path: '/Sponsorship',
      name: 'Sponsorship',
      component: Sponsorship,
    },
  ],
})
