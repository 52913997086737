<template>
  <div>
    <h-h-a />
  </div>
</template>

<script>
  export default {
    name: 'ClubSwag',

    components: {
      HHA: () => import('@/components/HHA/HHAMain'),

    },
  }
</script>
