<template>
  <div>
    <youth />
  </div>
</template>

<script>
  export default {
    name: 'Forms',
    components: {
      Youth: () => import('@/components/YouthArcheryTeam/YouthArcheryTeam'),

    },
  }
</script>
