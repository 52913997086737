<template>
  <div>
    <forms-main />
  </div>
</template>

<script>
  export default {
    name: 'Forms',
    components: {
      FormsMain: () => import('@/components/forms/FormsMain'),

    },
  }
</script>
