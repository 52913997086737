<template>
  <h1
    class="subheading text-uppercase font-weight-medium mb-3"
  >
    {{ title }}
    <slot />
  </h1>
</template>

<script>
  export default {
    name: 'BaseSubheading',

    props: {
      subtitle: String,
      text: String,
      title: String,
    },
  }
</script>
