<template>
  <div>
    <range-main-page />
  </div>
</template>

<script>
  export default {
    name: 'RangesHomePage',

    components: {
      RangeMainPage: () => import('@/components/ranges/RangeMainPage'),

    },
  }
</script>
