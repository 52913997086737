<template>
  <div>
    <calendar />
  </div>
</template>

<script>
  export default {
    name: 'CalendarView',
    components: {
      Calendar: () => import('@/components/Calendar/Calendar.vue'),

    },
  }
</script>
