<template>
  <div>
    <spot-league-main-page />
  </div>
</template>

<script>
  export default {
    name: 'SpotLeagueShootsView',
    components: {
      SpotLeagueMainPage: () => import('@/components/spotleagueshoots/SpotLeagueShoots'),

    },
  }
</script>
